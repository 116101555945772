
class Chatbot {
    constructor() {
        const styleElement = document.createElement("style");
        styleElement.textContent = ".pit-chat-btn { visibility: hidden; }";
        document.head.append(styleElement);
    }

    _init() {
        this.isReady = false;
        const scriptElement = document.createElement("script");
        scriptElement.setAttribute("src", JSON.parse(localStorage.getItem("ngStorage-configuration")).chatbot.url);
        scriptElement.setAttribute("id", "pit-chat-widget");
        document.head.appendChild(scriptElement);
        const interval = setInterval(() => {
            if (window.PITChatWidget && document.getElementById("pit-chat-btn")) {
                const employee = JSON.parse(localStorage.getItem("ngStorage-employeeInfo"));
                window.PITChatWidget.changeUser(employee.Id);
                window.PITChatWidget.onBuildChatCompleted = (ar) => {
                    const headerTextElements = ar.getElementsByClassName("pit-chat-area-header-text");
                    if (headerTextElements.length > 0) {
                        headerTextElements[0].innerHTML = "Поддержка продаж ЮПРО";
                    }

                    const closeBtnElements = ar.getElementsByClassName("pit-chat-close-btn");
                    if (closeBtnElements.length > 0) {
                        closeBtnElements[0].style.filter = "invert(1)";
                    }
                };
                this.isReady = true;
                this.toggle();
                clearInterval(interval);
            }
        }, 300);
    }

    toggle() {
        if (!this.isReady) {
            return this._init();
        }
        document.getElementById("pit-chat-btn").click();
    }

    /**
     * @deprecated
     * @description нужна только для старого фронта
     * @todo переписать логику на старом фронте, чтобы использовался toggle
     */
    isOpen() {
        return false;
    }

    /**
     * @deprecated
     * @description нужна только для старого фронта
     * @todo переписать логику на старом фронте, чтобы использовался toggle
     */
    open() {
        this.toggle();
    }

    /**
     * @deprecated
     * @description нужна только для старого фронта
     * @todo переписать логику на старом фронте, чтобы использовался toggle
     */
    hide() {
        this.toggle();
    }
}

if (window["chatbot"] === undefined) window["chatbot"] = new Chatbot();
